import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import View from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {fromLonLat} from 'ol/proj.js';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

const parser = new WMTSCapabilities();
let map;

fetch('https://tefenua.dev/api/wmts?request=GetCapabilities')
  .then(function (response) {
    return response.text();
  })
  .then(function (text) {
    const result = parser.read(text);
    const options = optionsFromCapabilities(result, {
      layer: 'TEFENUA:FOND',
      matrixSet: 'EPSG:4326',
    });


function polygonStyleFunction(feature, resolution) {
    return new Style({
      stroke: new Stroke({
        color: 'red',
        width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    });
}
  var vectorPolygons = new VectorLayer({
    source: new VectorSource({
      format: new GeoJSON(),
      url: 'data.geojson'
    })
    , style: polygonStyleFunction
  });

    map = new Map({
      layers: [
        new TileLayer({
          opacity: 1,
          source: new WMTS(options),
        }),
        vectorPolygons
      ],
      target: 'map',
      view: new View({
         center:fromLonLat([-149.568267,-17.527319]),
    zoom: 18,
      }),
    });
  });

